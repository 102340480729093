<template>
	<div class="sessions-history">
		<Loading v-if="isLoading" />

		<div class="detail-container" v-if="selectedSession">
			<div style="display: flex; align-items: center">
				<img
					src="../../assets//images/session/icon_location_bigparking.svg"
					alt="Add Circle Icon"
					class="icon-svg"
				/>
				<h3 class="h1" style="margin-left: 12px; margin-bottom: 4px">
					<router-link
						:to="`/session/${selectedSession.id}`"
						class="h1"
						style="text-decoration: none; color: #278ac9"
					>
						{{ selectedSession.parking && selectedSession.parking.code }} -
						{{ selectedSession.parking && selectedSession.parking.name }}
					</router-link>
				</h3>
			</div>
			<div class="sessions-history-address-wrapper">
				<img
					v-if="selectedSession.parking.legacyAddress.en.includes('(M)')"
					class="sessions-history-address-wrapper-m"
					src="../../assets/images/session/Métrro.svg"
				/>
				<p class="parking-address">
					{{
						selectedSession.parking &&
						selectedSession.parking.legacyAddress[activeLanguage.value].replace('(M)', ``)
					}}
				</p>
			</div>

			<table class="details">
				<tr>
					<td class="col2">{{ $t('sessionParking.sessionResult.sessionId') }}</td>
					<td class="spacer"></td>
					<td class="col2">{{ $t('sessionParking.sessionResult.Date') }}</td>
				</tr>
				<tr class="row-values">
					<td class="col">ID {{ selectedSession.sessionId }}</td>
					<td class="spacer"></td>
					<td class="col">{{ date(selectedSession.createdAt) }}</td>
				</tr>

				<tr>
					<td class="col2">{{ $t('sessionParking.sessionResult.StartedAt') }}</td>
					<td class="spacer"></td>
					<td class="col2">{{ $t('sessionParking.sessionResult.endedAt') }}</td>
				</tr>
				<tr>
					<td class="col">{{ time(selectedSession.startTime) || '-' }}</td>
					<td class="spacer"></td>
					<td class="col">{{ time(selectedSession.endTime) || '-' }}</td>
				</tr>

				<tr>
					<td class="col2">{{ $t('sessionParking.sessionResult.duration') }}</td>
					<td class="spacer"></td>
					<td class="col2">{{ $t('sessionParking.sessionResult.total') }}</td>
				</tr>
				<tr>
					<td class="col">
						{{ diffInTime(selectedSession.startTime, selectedSession.endTime) || '-' }}
					</td>
					<td class="spacer"></td>
					<td class="col">€{{ selectedSession.totalAmountPaid }}</td>
				</tr>

				<tr>
					<td class="col2">{{ $t('sessionParking.sessionResult.numberOfBicycles') }}</td>
					<td class="spacer"></td>
				</tr>
				<tr style="display: flex">
					<td class="col">
						<span>{{ selectedSession.bikes }}</span>
						<img
							src="../../assets//images/session/cycle.svg"
							alt="Add Circle Icon"
							class="icon-svg"
							style="vertical-align: bottom; margin-left: 16px"
						/>
					</td>
				</tr>
			</table>
		</div>
		<div class="paginator">
			<div class="left" @click="() => selectPage('prev')">
				<img
					src="../../assets/images/icon_arrow_left_dark_grey.svg"
					alt="Add Circle Icon"
					width="18"
					:class="{ disabled: selectedIndex == 0 }"
				/>
			</div>
			<div
				class="page-selector"
				v-for="(paginationItem, index) in paginationList"
				:key="'pagination-item-' + index"
				:class="{ selected: selectedIndex + 1 == paginationItem.pageNumber }"
				@click="() => selectPage(paginationItem.pageNumber)"
			>
				{{ paginationItem.pageNumber || '...' }}
			</div>
			<div class="right" @click="() => selectPage('next')">
				<img
					src="../../assets/images/icon_arrow_right_dark_grey.svg"
					alt="Add Circle Icon"
					width="18"
					:class="{ disabled: selectedIndex == userSessions.length - 1 }"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import Loading from '@/components/Loading.vue'
import { date, time, diffInTime } from '@/helpers'
import { getPaginationList } from '@/helpers/pagination'

export default {
	name: 'ProfileSessionHistory',
	components: {
		Loading,
	},
	props: {
		id: {
			type: Number,
		},
	},
	data() {
		return {
			selectedIndex: 0,
			selectedSession: null,
			paginationList: [],
		}
	},
	async mounted() {
		await this.getUserSessions(this.id)
		this.selectedSession = this.userSessions.length ? this.userSessions[0] : null
		this.setPaginationList()
		console.log()
	},
	watch: {
		async currentUser(newVal) {
			await this.getUserSessions(this.id)
			this.selectedSession = this.userSessions.length ? this.userSessions[0] : null
			this.setPaginationList()
		},
	},
	computed: {
		...mapState({
			currentUser: (state) => state.currentUser,
			userSessions: (state) => state.session.userSessions,
			isLoading: (state) => state.session.isLoading,
			activeLanguage: (state) => state.language,
		}),
	},
	methods: {
		...mapActions('session', {
			getUserSessions: 'getUserSessions',
		}),
		date,
		time,
		diffInTime,
		getPaginationList,
		selectPage(page) {
			switch (page) {
				case 'prev':
					this.selectedIndex = this.selectedIndex > 0 ? this.selectedIndex - 1 : this.selectedIndex
					break
				case 'next':
					this.selectedIndex =
						this.selectedIndex < this.userSessions?.length - 1
							? this.selectedIndex + 1
							: this.selectedIndex
					break
				default:
					this.selectedIndex =
						page && page > 0 && page <= this.userSessions?.length ? page - 1 : this.selectedIndex
					break
			}
			this.selectedSession = this.userSessions[this.selectedIndex]
			this.setPaginationList()
		},
		setPaginationList() {
			this.paginationList = this.getPaginationList(this.userSessions.length, this.selectedIndex + 1)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/profile/index';

.sessions-history {
	.detail-container {
		padding: 16px;
	}

	background: #fff;
	color: #3c3c3b;

	.parking-address {
		font-family: 'Poppins Bold';
		font-size: 12px;
		margin: 0 0 0 0.5rem;
	}

	.details {
		margin-top: 20px;
		width: 100%;

		.row-values {
			padding-top: 0px;
		}

		.col {
			font-size: 16px;
			line-height: 25px;
			font-family: 'Poppins Bold';
			color: #3c3c3b;
			margin: 0%;
			padding-top: 0px;
			padding-bottom: 16px;
		}

		.col2 {
			color: #898780;
			font-size: 12px;
			line-height: 18px;
			font-family: 'Poppins Bold';
			margin: 0%;
			padding-bottom: 4px;
			text-transform: uppercase;
		}

		table {
			border-collapse: collapse;
			width: 100%;
		}

		td {
			text-align: left;
			// padding: 8px;
		}

		td:nth-child(2) {
			text-align: right;
		}

		.spacer {
			// width: 10px;
			padding: 0;
		}
	}
}
.sessions-history-address-wrapper {
	display: flex;
	align-items: center;
	&-m {
		width: 4%;
		margin: 0 0 0 2rem;
	}
}
.h1 {
	color: #1b388a;
	text-align: center;
	font-weight: bold;
	font-family: Poppins Bold;
	margin-top: 0;
}

.detail-link {
	color: red;
	font-size: 12px;
	font-family: Poppins Bold;
	margin-top: 4px;
}

.paginator {
	background: #faf2d5;
	padding-top: 16px;
	display: flex;
	justify-content: center;

	.left {
		display: flex;
		cursor: pointer;
		margin-right: 8px;
	}

	.right {
		display: flex;
		cursor: pointer;
		margin-left: 8px;
	}

	.page-selector {
		cursor: pointer;
		padding: 0 2px;
		margin: 0 6px;

		&.selected {
			color: #e75131;
		}
	}

	.disabled {
		opacity: 0.3;
	}
}
</style>
